/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                if ($(window).width() < 992) {
                    $('.menu-item-has-children>a').on('click', function (e) {
                        if (e.target !== this) {
                            return;
                        }
                        e.preventDefault();
                        $(this).parent().toggleClass('open');
                    });

                    $(document).on('click touchstart', function () {
                        if ($('.menu-item-has-children').hasClass('open')) {
                            $('.menu-item-has-children').removeClass('open');
                        }
                    });
                }

                $(window).on("load", function () {
                    $('body').removeClass('fade-out');

                    $(window).on('beforeunload', function () {
                        $('body').addClass('fade-out-anim');
                    });
                });

                $(".bk-to-top").click(function() {
                    $("html, body").animate({ scrollTop: 0 }, "slow");
                    return false;
                });

                function add_marker($marker, map, i) {
                    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
                    var markerIcon = $marker.attr('data-marker');
                    var icon = {
                        url: markerIcon, // url
                        scaledSize: new google.maps.Size(50, 50), // scaled size
                    };

                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map,
                        animation: google.maps.Animation.DROP,
                        icon: icon
                    });
                    map.markers.push(marker);


                    // if marker contains HTML, add it to an infoWindow
                    if ($marker.html()) {
                        // create info window

                        var infowindow = new google.maps.InfoWindow({
                            content: $marker.html()
                        });

                        // show info window when marker is clicked
                        google.maps.event.addListener(marker, 'click', function () {
                            infowindow.open(map, marker);
                        });

                        /* If first in each statement open info window*/
                        if(i === 0) {
                            infowindow.open(map, marker);
                        }
                    }

                }

                function center_map(map) {
                    // vars
                    var bounds = new google.maps.LatLngBounds();
                    var center;
                    // loop through all markers and create bounds
                    var first = true;
                    $.each(map.markers, function (i, marker) {
                        var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                        if (i === 0) {
                            center = latlng;
                        }
                        // bounds.extend(latlng);
                        if (first === true) {
                            first = false;
                            map.setCenter(latlng);
                        }
                    });
                    // only 1 marker?
                    if (map.markers.length === 1) {
                        map.setZoom(16);
                    }
                    else {
                        map.setZoom(15);
                    }
                }

                function new_map($el) {
                    var $markers = $el.find('.marker');
                    var args = {
                        zoom: 16,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        disableDefaultUI: true,
                        styles: [
                            {
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative.land_parcel",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#bdbdbd"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#757575"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dadada"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#616161"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.line",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit.station",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#c9c9c9"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#cad2d4"
                                    },
                                    {
                                        "weight": 1.5
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#9e9e9e"
                                    }
                                ]
                            }
                        ]
                    };

                    var map = new google.maps.Map($el[0], args);
                    map.markers = [];
                    $markers.each(function (i) {
                        add_marker($(this), map, i);
                    });

                    // center map
                    center_map(map);
                    // return
                    return map;


                }

                $('#map').each(function () {
                    // create map
                    var map = new_map($(this));
                });

                $('a[href="#overview"]').on('click', function () {
                    $('.details-link[href="#detail-content"]').tab('show');
                });

                // var margin = 40;
                // $('.property-details__tabs').scrollToFixed({
                // marginTop: margin,
                // limit: function() {
                //     var limit = $('.property-details__plan').offset().top - $(this).outerHeight(true) - 10;
                //     return limit;
                // }
                // });
                // $('.property-details__plan').scrollToFixed({
                //     marginTop: $('.property-details__tabs').outerHeight() + 10,
                //     limit: function() {
                //         var limit = $('.grid__layout').offset().top - $(this).outerHeight(true) - margin;
                //         return limit;
                //     }
                // });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                //If page has galley, add slider
                // var $gallery = $('#gallery');
                // if($gallery !== null) {
                //     var $swiper = new Swiper('.gallery-container', {
                //         speed: 400,
                //         autoplay: true,
                //         spaceBetween: 30,
                //         slidesPerView: 1,
                //         loop: true
                //     });
                // }

                //Smooth scroll on .anchor class

                $('.anchor').on('click touchstart', function (event) {
                    if (this.hash !== "") {
                        event.preventDefault();
                        var hash = this.hash;
                        $('html, body').animate({
                            scrollTop: $(hash).offset().top - 20
                        }, 900);
                    }
                });

                //Photoswipe lightbox if exists in DOM
                var $images = $('.section-gallery');
                if ($images.length > 0) {
                    var openSwipe = function (photoIndex) {
                        var pswpElement = document.querySelectorAll('.pswp')[0];
                        var items = [];
                        $(".single-image>img").each(function () {
                            var obj = {
                                src: this.src,
                                w: this.naturalWidth,
                                h: this.naturalHeight
                            };
                            items.push(obj);
                        });
                        // define options (if needed)
                        console.log(photoIndex);
                        var options = {
                            index: parseInt(photoIndex),
                            bgOpacity: 0.7,
                            showAnimationDuration: 400,
                            hideAnimationDuration: 400,
                            loop: true,
                            loadingIndicatorDelay: 10
                        };
                        console.log(options);
                        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
                        gallery.init();
                    };
                }

                $('.single-image').on('click', function () {
                    openSwipe($(this).attr('data-index'));
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'blog': {
            init: function () {
                var mixer = mixitup('.grid-row', {
                    animation: {
                        duration: 750,
                        effectsOut: 'fade translateZ(-100px)',
                    }
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
